import { useState } from "react";
import { addHours } from "date-fns";
import { PurpleButton } from "../../../globalStyles/emotion";
import Duration from "../../../components/duration/Duration";

import DashTimeFormat from "../../../components/time/DashTimeFormat";

import CompleteRideCard from "../../../components/completeRideCard/CompleteRideCard";
import StartRideCard from "../../../components/startRideCard/StartRideCard";
import NoShowRideCard from "../../../components/noShowRideCard/NoShowRideCard";
import SidePanelDetails from "../../../components/sidePanelDetails/SidePanelDetails";

const ConfirmedViewSidePanel = ({
  rowId,
  confirmedRides,
  setReloadData,
  setClaimRideMessage,
  query,
}) => {
  const [startRideId, setStartRideId] = useState(null);
  const [noShowRideId, setNoShowRideId] = useState(null);
  const [toggleClaimId, setToggleClaimId] = useState(null);
  const [inputTolls, setInputTolls] = useState(null);
  const [inputParking, setInputParking] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [alert, setAlert] = useState(false);

  const handleClaim = (
    event,
    requestedStartTime,
    requestedEndTime,
    tollsCents,
    parkingCents
  ) => {
    setToggleClaimId(event.target.value);
    setValue(new Date(requestedStartTime));
    setValue2(
      requestedEndTime
        ? new Date(requestedEndTime)
        : addHours(new Date(requestedStartTime), 1)
    );
    setInputTolls(tollsCents);
    setInputParking(parkingCents);
  };

  return (
    <div style={{ flex: 1 }}>
      {confirmedRides.map(
        ({
          vehicleSummary,
          custodianDisplayName,
          custodianPhone,
          custodianEmail,
          driverId,
          driverDisplayName,
          driverPhone,
          driverEmail,
          startLocationAddress,
          endLocationAddress,
          isBariatric,
          isInsurance,
          suppressPayment,
          riderDisplayName,
          riderPhone,
          estimatedPayout,
          bonus,
          estimatedTotal,
          startLocationNotes,
          endLocationNotes,
          startLocationName,
          endLocationName,
          id,
          hasStairs,
          rideNotes,
          tollsCents,
          parkingCents,
          rideType,
          transportTypeId,
          moveToRide,
          riderEmail,
          riderNumber,
          riderNotes,
          riderWeightDisplay,
          riderHeightDisplay,
          oxygenRequired,
          contactPrecautionsRequired,
          estimatedDurationMinutes,
          estimatedDistanceMiles,
          requestedStartTime,
          requestedEndTime,
          startLocationLatitude,
          startLocationLongitude,
          endLocationLatitude,
          endLocationLongitude,
          riderWillUseProvidersWheelchair,
          status,
          firstAvailable,
          payerName,
          payerType,
          costCenter,
          startLocationRoomNumber,
          endLocationRoomNumber,
          bedsideNurseFullName,
          bedsideNursePhone,
          isLongDistance,
          riderDob,
          carePartnerDisplayName,
          carePartnerPhone,
          carePartnerRelationshipType,
          carePartnerRelationshipNotes,
          appointmentTime,
          transportReasonName,
          transportReasonCategoryName,
          transportReasonComment,
        }) => {
          return (
            <div key={id}>
              {id == rowId ? (
                <>
                  <div
                    style={{
                      background: "rgba(0,128,128,0.2)",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      height: "70px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTopRightRadius: "25px",
                    }}
                  >
                    <DashTimeFormat
                      requestedStartTime={requestedStartTime}
                      requestedEndTime={requestedEndTime}
                    />

                    <div style={{ display: "flex", gap: "10px" }}>
                      {startRideId === id ? (
                        <StartRideCard
                          rideId={startRideId}
                          onClose={() => setStartRideId(null)}
                          setClaimRideMessage={setClaimRideMessage}
                          setReloadData={setReloadData}
                        />
                      ) : status === "confirmed" ? (
                        <PurpleButton
                          variant="contained"
                          onClick={() => setStartRideId(id)}
                          value={id}
                        >
                          Start Ride
                        </PurpleButton>
                      ) : null}

                      {noShowRideId === id ? (
                        <NoShowRideCard
                          rideId={noShowRideId}
                          onClose={() => setNoShowRideId(null)}
                          setClaimRideMessage={setClaimRideMessage}
                          setReloadData={setReloadData}
                        />
                      ) : status === "in-progress" ? (
                        <PurpleButton
                          variant="contained"
                          onClick={() => setNoShowRideId(id)}
                          value={id}
                        >
                          No Show
                        </PurpleButton>
                      ) : null}

                      {toggleClaimId == id ? (
                        <CompleteRideCard
                          rideType={rideType}
                          toggleClaimId={toggleClaimId}
                          error={error}
                          setAlert={setAlert}
                          value={value}
                          setValue={setValue}
                          value2={value2}
                          setValue2={setValue2}
                          setInputTolls={setInputTolls}
                          inputParking={inputParking}
                          setInputParking={setInputParking}
                          inputTolls={inputTolls}
                          driverId={driverId}
                          id={id}
                          alert={alert}
                          setReloadData={setReloadData}
                          setToggleClaimId={setToggleClaimId}
                          setClaimRideMessage={setClaimRideMessage}
                          setError={setError}
                          estimatedDurationMinutes={estimatedDurationMinutes}
                          transportTypeId={transportTypeId}
                        />
                      ) : (
                        <PurpleButton
                          variant="contained"
                          onClick={(event) =>
                            handleClaim(
                              event,
                              requestedStartTime,
                              requestedEndTime,
                              tollsCents,
                              parkingCents
                            )
                          }
                          value={id}
                        >
                          Complete
                        </PurpleButton>
                      )}
                    </div>
                  </div>

                  <SidePanelDetails
                    id={id}
                    transportTypeId={transportTypeId}
                    rideType={rideType}
                    custodianDisplayName={custodianDisplayName}
                    riderDisplayName={riderDisplayName}
                    hasStairs={hasStairs}
                    isBariatric={isBariatric}
                    isInsurance={isInsurance}
                    suppressPayment={suppressPayment}
                    startLocationName={startLocationName}
                    startLocationAddress={startLocationAddress}
                    startLocationNotes={startLocationNotes}
                    endLocationNotes={endLocationNotes}
                    endLocationAddress={endLocationAddress}
                    startLocationLatitude={startLocationLatitude}
                    startLocationLongitude={startLocationLongitude}
                    Duration={Duration}
                    estimatedDurationMinutes={estimatedDurationMinutes}
                    estimatedDistanceMiles={estimatedDistanceMiles}
                    custodianPhone={custodianPhone}
                    custodianEmail={custodianEmail}
                    driverEmail={driverEmail}
                    driverPhone={driverPhone}
                    riderEmail={riderEmail}
                    riderPhone={riderPhone}
                    rideNotes={rideNotes}
                    endLocationName={endLocationName}
                    requestedStartTime={requestedStartTime}
                    requestedEndTime={requestedEndTime}
                    vehicleSummary={vehicleSummary}
                    estimatedPayout={estimatedPayout}
                    bonus={bonus}
                    riderWillUseProvidersWheelchair={
                      riderWillUseProvidersWheelchair
                    }
                    estimatedTotal={estimatedTotal}
                    riderWeightDisplay={riderWeightDisplay}
                    riderHeightDisplay={riderHeightDisplay}
                    oxygenRequired={oxygenRequired}
                    contactPrecautionsRequired={contactPrecautionsRequired}
                    riderNotes={riderNotes}
                    driverDisplayName={driverDisplayName}
                    endLocationLatitude={endLocationLatitude}
                    endLocationLongitude={endLocationLongitude}
                    status={status}
                    firstAvailable={firstAvailable}
                    driverId={driverId}
                    payerName={payerName}
                    payerType={payerType}
                    costCenter={costCenter}
                    startLocationRoomNumber={startLocationRoomNumber}
                    endLocationRoomNumber={endLocationRoomNumber}
                    bedsideNurseFullName={bedsideNurseFullName}
                    bedsideNursePhone={bedsideNursePhone}
                    isLongDistance={isLongDistance}
                    riderDob={riderDob}
                    carePartnerDisplayName={carePartnerDisplayName}
                    carePartnerPhone={carePartnerPhone}
                    carePartnerRelationshipType={carePartnerRelationshipType}
                    carePartnerRelationshipNotes={carePartnerRelationshipNotes}
                    appointmentTime={appointmentTime}
                    transportReasonName={transportReasonName}
                    transportReasonCategoryName={transportReasonCategoryName}
                    transportReasonComment={transportReasonComment}
                  />
                </>
              ) : null}
            </div>
          );
        }
      )}
    </div>
  );
};

export default ConfirmedViewSidePanel;
