import {
  Alert,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ENV from "../../constants/Environment";
import { DisabledButton, SecondaryButton } from "../../globalStyles/emotion";
import { ConfirmationDialog } from "../../components/dialogs";
import handleLogout from "../auth/Logout";
import Switch from "@mui/material/Switch";
import { LocalizationProvider as LocalizationProviderDatePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { add, compareAsc } from "date-fns";
import TimeZoneTimeText from "./TimeZoneTimeText";

const { apiUrl } = ENV;

const CompleteRideCard = ({
  toggleClaimId,
  error,
  alert,
  setAlert,
  value,
  setValue,
  value2,
  setValue2,
  setInputTolls,
  inputParking,
  setInputParking,
  inputTolls,
  driverId,
  id,
  setReloadData,
  setToggleClaimId,
  setClaimRideMessage,
  setError,
  estimatedDurationMinutes,
  transportTypeId
}) => {
  const closebutton = () => {
    setToggleClaimId(null);
    setAlert(false);
    setError(null);
  };
  const [disable, setDisable] = useState(false);
  const [reviewOptions, setReviewOptions] = useState([]);

  const startDate = new Date(value);
  const endDate = new Date(value2);

  const valueRef = useRef(value);
  const value2Ref = useRef(value2);

  const handleCompleteRide = (driverId, id) => {
    const isStartTimeAfterEndTime = compareAsc(startDate, endDate) === 1;

    if (isStartTimeAfterEndTime) {
      setAlert(true);
      setError("Start Time can not be greater then End Time");
    } else {
      axios
        .post(
          `${apiUrl}/Rides/CompleteRide`,
          {
            driver_id: driverId,
            actual_start_time: startDate.toISOString(),
            actual_end_time: endDate.toISOString(),
            parking_cents: inputParking * 100,
            tolls_cents: inputTolls * 100,
            ride_id: id,
            review_options: reviewOptions.map((ro) => ({
              slug: ro.slug,
              value: ro.value,
            })),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "User-Id": localStorage.getItem("userid"),
            },
          }
        )
        .then((response) => {
          setDisable(true);
          setReloadData(true);
          setToggleClaimId(null);
          setClaimRideMessage("Ride has been completed");
          if (response.data.message === false) {
            setError("Invalid Input");
          } else {
            setError(null);
          }
        })
        .then(() => {
          setInterval(() => {
            setDisable(false);
            setReloadData(false);
          }, 2000);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handleLogout();
          } else {
            setAlert(error.response.data.detail);
            setError(error.response.data.detail);
          }
        })
        .then(() => {
          setInterval(() => {
            setDisable(false);
          }, 2000);
        });
    }
    setDisable(true);
  };

  useEffect(() => {
    if (alert === true) {
      setDisable(false);
    }
  }, [alert]);

  useEffect(() => {
    fetch(`${ENV.apiUrl}/Rides/GetRideReviewOptions?rideId=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((d) => d.json())
      .then((response) => {
        setReviewOptions(response.reviewOptions);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const toggleValue = (slug, event) => {
    const roIndex = reviewOptions.findIndex((ro) => ro.slug === slug);

    if (roIndex > -1) {
      setReviewOptions((prev) => {
        const newReviewOpt = [...prev];
        newReviewOpt[roIndex] = {
          ...newReviewOpt[roIndex],
          value: event.target.checked.toString(),
        };
        return newReviewOpt;
      });
    }
  };

  return (
    <ConfirmationDialog maxWidth={"sm"} fullWidth open={toggleClaimId}>
      <ConfirmationDialog.Title>Complete Ride</ConfirmationDialog.Title>

      <ConfirmationDialog.Body>
        {alert ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        ) : null}

        <div>
          <div>
            <InputLabel
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Start Time
            </InputLabel>
            <div>
              <LocalizationProviderDatePicker dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  value={new Date(value)}
                  onChange={(v) => setValue(v)}
                  minDate={add(new Date(valueRef.current), { days: -1 })}
                  maxDate={add(new Date(valueRef.current), {
                    days: 1,
                    minutes: estimatedDurationMinutes,
                  })}
                />
              </LocalizationProviderDatePicker>
              <TimeZoneTimeText date={value} />
            </div>
          </div>

          <div>
            <InputLabel
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              End Time
            </InputLabel>
            <div>
              <LocalizationProviderDatePicker dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  value={new Date(value2)}
                  onChange={(v) => setValue2(v)}
                  minDate={add(new Date(value2Ref.current), { days: -1 })}
                  maxDate={add(new Date(value2Ref.current), {
                    days: 1,
                  })}
                />
              </LocalizationProviderDatePicker>
              <TimeZoneTimeText date={value2} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div style={{}}>
              <InputLabel style={{ marginBottom: "10px" }}>Tolls</InputLabel>

              <TextField
                style={{ width: "100px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={inputTolls}
                onChange={(event) => {
                  setInputTolls(event.target.value);
                }}
              />
            </div>
            <div>
              <InputLabel style={{ marginBottom: "10px" }}>Parking</InputLabel>
              <TextField
                style={{ width: "100px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={inputParking}
                onChange={(event) => {
                  setInputParking(event.target.value);
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            {reviewOptions?.map((ro) => {
              // transportTypeId === 1 means COMPANION
              if (
                ro.slug === 'contact_precautions_required' &&
                transportTypeId === 1
              ) {
                return <></>;
              }
              return (
                <div
                style={{ display: "flex", justifyContent: "space-between" }}
                key={ro.name}
              >
                <Typography>{ro.label}</Typography>
                <Switch
                  onChange={(event) => toggleValue(ro.slug, event)}
                  checked={ro.value === "true"}
                />
              </div>
            )})}
          </div>

          <p
            style={{
              marginTop: "20px",
            }}
          >
            Are you Sure you want to complete Ride?
          </p>
        </div>
      </ConfirmationDialog.Body>

      <ConfirmationDialog.Footer>
        <SecondaryButton variant="outlined" onClick={closebutton}>
          No
        </SecondaryButton>

        <DisabledButton
          variant="contained"
          disabled={disable}
          onClick={() => handleCompleteRide(driverId, id)}
        >
          Yes
        </DisabledButton>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};

export default CompleteRideCard;
